<template>
  <div>
    <div class="groupDistri" style="width: 100%; height: 100%" >
      <el-row :gutter="30" class="box" ref="box" style="padding: 5px 5px 5px 15px; margin-top: 10px">
        <el-col :span="24" style="display: flex; justify-content: space-around">
          <div class="leftBox" ref="leftBox" style="display: flex">
            <div class="leftTree" style="max-width: 60%">
              <div class="wrap_tree" id="myDiv" v-if="bigflag == 1">
                <el-input v-model="searchGroup" size="mini" type="text" :placeholder="$t('UserManage.section.leftTree.TreeCue')" style="width: 100%" v-on:input="onSearch" @input="inputChange" @focus="focus">
                  <i @click="onSearch" slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer"></i>
                </el-input>
                <!-- //模糊搜索弹框 -->
                <div v-if="searchGroup">
                  <ul style="
                      overflow: hidden auto;
                      padding: 1px 0px;
                      height: 100%;
                      box-shadow: 0px 0px 10px rgb(232, 232, 232);
                    " class="souBox">
                    <li class="liHover" style="
                        height: 30px;
                        line-height: 30px;
                        padding-left: 14px;
                        border: 1px solid #c6c6c6;
                        color: gray;
                        margin-top: -1px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                      " :title="item.groupName" v-for="(item, index) in searchResult" :key="index" @click="clickList(item)">
                      {{ item.groupName }}
                    </li>
                  </ul>
                </div>
                <div v-else class="treeBox"> 
                  <TreeList v-if="mageanTree" @tree="trees" @click="child" />
                </div>
              </div>
              <div class="dragPoint" style="right: -11px">
                <i>
                  <img src="../../../images/icon_drag.png" alt="" />
                </i>
              </div>
            </div>
            <div v-if="flag == 1" class="leftTable" style="width: calc(100% - 253px);">
              <div class="bigbox" ref="leftTable">

                <el-row :gutter="4" class="mt15" style="display: flex;">
                  <el-col :span="3">
                    <el-input @keypress.enter.native="onSbumit"   @keyup.native="btKeyUp"  size="small"  v-model.trim="input" :placeholder="$t('UserManage.section.rightBig.placeholder')"></el-input>
                  </el-col>
                  <el-col :span="3">
                    <el-button type="primary" size="small" @click="onSbumit">{{$t('public.Inquire')}}</el-button>
                  </el-col>
                </el-row>
                <div class="buttonGroupDiv">

                  <el-button-group>
                    <el-button type="primary" style="height: 31px; margin-left: 1px" size="small" @click="addBtn">{{$t('public.New')}}</el-button>
                    <el-button style="margin-left: 1px; height: 31px" @click="delMuch(delarr)" :type="info" size="small" :disabled="flagStatus" class="delBtn">{{$t('public.Delete')}}</el-button>
                  </el-button-group>

                </div>
                <el-row :gutter="16" style=" margin:10px 0px 0px 10px">

                  <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" :row-key="getRowKeys" size="mini" ref="multipleTable" :data="tableData" class="tableClass " stripe tooltip-effect="dark" style="width: 100%;" :default-sort="{
                      prop: 'lastUpdateTime',
                      order: 'descending',
                    }" @sort-change="sort" @selection-change="handleSelectionChange">
                    <!-- -->
                    <el-table-column :reserve-selection="true" header-align="center" align="center" type="selection" width="60" :selectable="selectEnable">
                    </el-table-column>
                    <el-table-column sortable="custom" prop="groupName" :label="$t('UserManage.section.rightBig.DepartmentList.DepartmentName')" :show-overflow-tooltip="true" width="150">
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true" width="150" prop="parentName" :label="$t('UserManage.section.rightBig.DepartmentList.SuperiorDepartment')">
                      <template slot-scope="scope">
                        <span v-if="scope.row.parentName">
                          {{ scope.row.parentName.substr(groupFullNameShowByIndex)}}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true" width="150" prop="memo" :label="$t('UserManage.section.rightBig.DepartmentList.Description')">
                    </el-table-column>
                    <el-table-column prop="lastUpdateTime" :label="$t('public.ModificationTime')" sortable="custom" :show-overflow-tooltip="true" width="150">
                      <template slot-scope="scope">
                        <span v-if="scope.row.time == ''"></span><span class="detaildesc" v-else>
                          {{ scope.row.lastUpdateTime | dateformat }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('public.Controls')">
                      <template v-slot="{ row }">
                        <span class="action_icon">
                          <img :title="$t('public.Editor')" @click="edit(row)" src="../../../assets/icon_write.png" alt=""  />
                        </span>
                        <span class="action_icon">
                          <img :title="$t('public.Delete')" v-show="row.id != id" @click="deletes(row)" src="../../../assets/icon_del.png" alt=""  />
                        </span>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-row>

                <el-row :gutter="16" style=" margin:10px 0px 0px 10px">
                  <pagination :page="queryData.currentPage" :limit="queryData.pageSize" :total="queryData.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
                </el-row>
                <!-- <ul class="tableCheckBox" style="" v-show="isCheckShow" @mouseleave="leave">
                  <li @click="curSelection()">当前页全选</li>
                 <li @click="toggleAllSelection()">所有页全选</li>
                  <li @click="toggleSelection()">取消全选</li>
                </ul> -->
              </div>
            </div>
            <!-- 新增 -->
            <div v-if="flag == 2">
              <div class="leftTable" style="
                  width: 500px;
                  height: 800px;
                  margin-top: 25px;
                  margin-left: 60px;
                ">
                <el-form size="small" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" @submit.native.prevent class="demo-ruleForm">
                  <el-form-item :label="$t('UserManage.section.rightBig.addList.DepartmentName')" prop="name">
                    <el-input style="width: 250px" v-model="ruleForm.name"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('UserManage.section.rightBig.addList.Description')" prop="desc">
                    <el-input style="width: 250px" type="textarea" maxlength="200" :rows="4" v-model="ruleForm.desc"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">{{$t('public.Save')}}</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 提示消息框 -->
    <dialogInfo :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>

<script> 
import TreeList from '@/components/treeSulation/userTree.vue' //树形组织
import dialogInfo from '@/components/promptMessage/index' //提示消息框
import pagination from '@/components/pagination/page.vue' //分页
export default {
  components: {
    pagination,
    dialogInfo,
    TreeList
  },
  data() {
    let validcodeName = (rule, value, callback) => {
      // ^\a-\z\A-\Z0-9\u4E00-\u9FA5
      // ^<>.,?;:'()!~%-_@#%/*""\s]+$"不允许输入特殊符号和字符
      let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/
      if (!reg.test(value)) {
        callback(new Error('不支持空格及特殊字符'))
      } else {
        callback()
      }
    }
    return {
      bigflag: 1, //大开关
      parentId:'',//新增、编辑 parentId
      groupFullNameShowByIndex: '',
      mageanTree: true,//刷新左侧树形结构
      addArray: '',
      flagStatus: true,
      flag: 1, //显示隐藏切换
      input: '', //列表查询
      delarr: [], //多个删除存放数组
      info: 'info',
      searchGroup: '', //树状图中查询部门的查询内容
      nodeClickFlag: false, //节点是否点击标志
      input: '', //用户的查询列表
      data: [], //树组
      visibaelFlag: false, //提示消息弹框默delNum认不显示
      historyList: [], //搜索历史记录
      focusFlag: false,
      fuzzyFlag: false,
      showFlag: false, //搜索数据显示---默认隐藏
      historyListFlag: false, //搜索历史显示--默认隐藏
      searchResult: [], //模糊搜索结果
      treeShow: true, //树状图显示----默认显示 
      flagDisable: false,// 遮罩全选
      // isCheckShow: false,
      titleInfo: '', //消息的字体
      tableData: [],
      search: {
        where_group: 0,
        pageNumber: 1,
        rowNumber: 10,
        where: {
          search0: '43',
          groupId: ''
        },
        sort: 'lastUpdateTime desc'
      },
      queryData: {
        total: 0,
        currentPage: 1,  
        pageSize: 10,  
        orderColume: 'lastUpdateTime',
        orderRule: 'DESC',
        searchName: '' 
      },
      value: '',
      id: '',
      ruleForm: {
        id: '',
        name: '',
        desc: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入部门名称', trigger: 'blur' },

          { max: 20, message: '最大输入20个字符', trigger: 'blur' },
          { validator: validcodeName, trigger: 'blur' }
        ] 
      },
      IDS: ''
    }
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
    this.id = sessionStorage.getItem('manageGroupId')
    this.dragControllerDiv() //树状图外部盒子拖拽
    if(document.styleSheets[0].cssRules.length>=2){
      document.styleSheets[0].deleteRule(0);
    }

  },
  methods: {
         //限制输入特殊字符
         btKeyUp(e) {
      e.target.value = e.target.value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ''
        )
        .replace(/\s/g, '')
    },
    getRowKeys(row) {
      return row.id
    },
    trees(val) {
      this.id = val
      this.parentId=val,
      this.input = ''
      this.queryData.currentPage = 1
      this.dataList(this.id)
    },
    child() {
      this.$refs.child.show()
    },
    //仅对 type=selection 的列有效，类型为 Function，Function 的返回值用来决定这一行的 CheckBox 是否可以勾选
    selectEnable(row, rowIndex) {
      if (
        row.id == this.id
        // row.dictionaryName == "典型案例" ||
        // row.relationNumber > 0
      ) {
        return false
      } else {
        return true
      }
    }, 
    onSearch() {},//树状图查询部门 
    clickList(v) {//点击搜索结果
      console.log(v, '---点击v ', v.id)
      this.currentNodekey = v.id
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(this.currentNodekey) //一定要加这个选中了否则样式没有出来
      })
      this.dataList(v.id)
    }, 
    focus() {},//获取焦点 
    async inputChange(v) { //搜索接口
      if (v.length > 0) {
        this.searchGroup = v
        var params = { searchName: this.searchGroup }
        const res = await this.$axios.post(
          '/httpServe/groups/selectListByName',
          params,
          true
        )
        //   this.fuzzyFlag = true
        this.showFlag = true
        this.treeShow = false
        this.searchResult = res.data.content
      } else {
        this.showFlag = false
        this.treeShow = true
      }
    },
    // 转换树行数据的方法
    transTree(list) {
      // 最终生成的树行结构
      const data = []
      list.forEach((item) => {
        // 给每一项添加子节点
        // item.children = []
        // 如果 pid 为空，说明是最顶级，直接放到 treeData 中即可
        if (item.pid == 'G_0') {
          data.push(item)
        }
        // 根据前面的分析，pid 代表的是父级的 id，从而可以进行筛选子级
        const children = list.filter((data) => data.pid === item.id)
        if (!children.length) return
        // 将返回的子级进行赋值给父级的 children
        item.children = children
      })
      return data
    },
    // 表格列表
    async dataList(v) {
      this.id = v 
      var param = {
        currentPage: this.queryData.currentPage,
        pageSize: this.queryData.pageSize,
        orderColume: this.queryData.orderColume,
        orderRule: this.queryData.orderRule,
        groupId: this.id,
        searchName: this.queryData.searchName
      } 
      const res = await this.$axios.post(
        '/httpServe/groups/getDataInfo',
        param,
        true
      )
      this.tableData = res.data.content
      this.queryData.total = res.data.total
    },
    // 排序
    sort(column) {
      if (column.prop == 'groupName') {
        if (column.order == 'ascending') {
          this.queryData.orderColume = 'groupName'
          this.queryData.orderRule = 'ASC'
        } else {
          this.queryData.orderColume = 'groupName'
          this.queryData.orderRule = 'DESC'
        }
      } else if (column.prop == 'lastUpdateTime') {
        if (column.order == 'ascending') {
          this.queryData.orderColume = 'lastUpdateTime'
          this.queryData.orderRule = 'ASC'
        } else {
          this.queryData.orderColume = 'lastUpdateTime'
          this.queryData.orderRule = 'DESC'
        }
      }
      this.dataList(this.id)
    },
    // 新增
    addBtn() {
      this.flag = 2
      document.styleSheets[0].insertRule("#myDiv {pointer-events: none;}", 0);
    },
    // 新增
    async newAdd() {
      this.mageanTree = false

      if (this.IDS) {
        var param = {
          id: this.IDS,
          parentId:this.parentId,
          groupName: this.ruleForm.name,
          memo: this.ruleForm.desc
        }
        const res = await this.$axios.post(
          '/httpServe/groups/update',
          param,
          true
        )
        if (res.data == 0) {
          this.ruleForm = {}
          this.mageanTree = true
          this.$message({
            message: this.$t('public.SuccessfullySaved'),
            type: 'success',
            offset: 100
          })
          this.IDS = ''
          this.flag = 1
          this.bigflag = 1;
          document.styleSheets[0].deleteRule(0)
          this.dataList(this.id)
        } else { 
          this.$message({
            message: this.$t('UserManage.section.rightBig.addList.saveError'),
            type: 'error',
            offset: 100
          })
          this.mageanTree = true
        }
      } else {
        var param = {
          groupName: this.ruleForm.name,
          memo: this.ruleForm.desc,
          parentId: this.parentId
        }
        const res = await this.$axios.post(
          '/httpServe/groups/insert',
          param,
          true
        )

        if (res.data == -1) { 
          this.$message({
            message: this.$t('UserManage.section.rightBig.addList.saveError'),
            type: 'error',
            offset: 100
          })
          this.mageanTree = true 
        } else {
          this.flag = 1 
          this.ruleForm = {}
          this.mageanTree = true
          this.$message({
            message: this.$t('public.SuccessfullySaved'),
            type: 'success',
            offset: 100
          })
          this.bigflag = 1;
          document.styleSheets[0].deleteRule(0)
          this.dataList(this.id)
        }
      }
    },
    edit(val) {// 编辑
      this.IDS = val.id
      this.parentId = val.parentId
      this.ruleForm = val
      this.flag = 2
      this.$set(this.ruleForm, 'name', val.groupName)
      this.$set(this.ruleForm, 'desc', val.memo)
      this.$set(this.ruleForm, 'id', val.id)
      document.styleSheets[0].insertRule("#myDiv {pointer-events: none;}", 0);
    },
    submitForm(formName) {// 保存
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.newAdd()
        } else {
          return false
        }
      })
    },
    deletes(val) {// 删除
      this.$confirm(this.$t('UserManage.section.rightBig.addList.delConfirmMessage'), this.$t('public.PromptMessage'), {
        confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(() => {
          this.del(val)
        })
        .catch(() => {
          return false
        })
    },
    async del(row) {// 删除
      var param = {
        ids: [row.id + '']
      }
      const res = await this.$axios.post(
        '/httpServe/groups/delete',
        param,
        true
      )
      if (res.data == -1) {
        this.$message({
          message: this.$t('UserManage.section.rightBig.addList.delLose'),
          type: 'error',
          offset: 100
        })
        this.$refs.multipleTable.clearSelection(); //清除之前的选中状态
      }else if (res.data == -2) { 
            this.$message({
             message: this.$t('UserManage.section.rightBig.addList.delLoseTwo'),
             type: 'error',
             offset: 100
           })
          this.$refs.multipleTable.clearSelection(); //清除之前的选中状态
   } else {
        this.$message({
          message: this.$t('public.SuccessfullyDeleted'),
          type: 'success',
          offset: 100
        })
        this.mageanTree = false
        setTimeout(() => {
          this.mageanTree = true
        }, 100)
        
        this.bigflag = 1;
        this.dataList(this.id)
        this.$refs.multipleTable.clearSelection(); //清除之前的选中状态 
      }
    },
    async delMuch(obj) {//多条删除
      this.$confirm(this.$t('UserManage.section.rightBig.addList.delConfirmMessage'), this.$t('public.PromptMessage'), {
        confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          var ids = []
          obj.forEach((item) => {
            ids.push(item.id)
          })
          var param = {
            ids: ids
          }
          const res = await this.$axios.post(
            '/httpServe/groups/delete',
            param,
            true
          )
          if (res.data == -1) {
            this.$message({
              message: this.$t('UserManage.section.rightBig.addList.delLose'),
              type: 'error',
              offset: 100
            })
            this.$refs.multipleTable.clearSelection(); //清除之前的选中状态
          }else if (res.data == -2) {
            this.$message({
              message: this.$t('UserManage.section.rightBig.addList.delLoseTwo'),
              type: 'error',
              offset: 100
            })
            this.$refs.multipleTable.clearSelection(); //清除之前的选中状态
          } else {
            this.$message({
              message: this.$t('public.SuccessfullyDeleted'),
              type: 'success',
              offset: 100
            })
            this.mageanTree = false
            setTimeout(() => {
              this.mageanTree = true
            }, 100) 
            this.bigflag = 1;
            this.dataList(this.id)
            this.$refs.multipleTable.clearSelection(); //清除之前的选中状态 
          }
        })
        .catch(() => {
          return false
        })
    },
    async onSbumit() {// 查询 onSbumit
      if (this.input.length > 0 ) {
        this.queryData.currentPage = 1
      }
      var param = {
        currentPage: this.queryData.currentPage,
        pageSize: this.queryData.pageSize,
        orderColume: this.queryData.orderColume,
        orderRule: this.queryData.orderRule,
        groupId: this.id, //""
        searchName: this.input
      }
      const res = await this.$axios.post(
        '/httpServe/groups/getDataInfo',
        param,
        true
      )
      this.tableData = res.data.content
      this.queryData.total = res.data.total
    },
    handleClose() {// 弹框点击关闭事件
      this.statusVisible = false //点击出现详情弹框
      this.visibaelFlag = false //提示消息弹框关闭
    },
    determine() {//确定的关闭
      this.visibaelFlag = false
    },
    handleSelectionChange(obj) {//表格当删除发生变化
      this.delarr = obj
      let dataArr = [...obj]
      if (dataArr.length == 0) {
        this.info = 'info'
        this.flagStatus = true
      } else {
        this.info = 'primary'
        this.flagStatus = false
      }
    },
    handleParentGetList(page, limit) {// 响应分页组件查询事件
      this.queryData.currentPage = page
      this.queryData.pageSize = limit
      this.onSbumit()// 调用列表
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return 'warning-row'
      } else if (rowIndex === 3) {
        return 'success-row'
      }
      return ''
    },
    dragControllerDiv: function () {//树状图外部盒子拖拽
      var dragPoint = document.getElementsByClassName('dragPoint')
      var leftTree = document.getElementsByClassName('leftTree')
      var leftTable = document.getElementsByClassName('leftTable')
      var leftBox = document.getElementsByClassName('leftBox')
      for (let i = 0; i < dragPoint.length; i++) {
        // 鼠标按下事件
        dragPoint[i].onmousedown = function (e) {
          //颜色改变提醒
          // dragPoint[i].style.background = "#818181";
          var startX = e.clientX //鼠标按下时鼠标指针的横向坐标
          dragPoint[i].leftTree = dragPoint[i].offsetLeft
          // 鼠标拖动事件
          document.onmousemove = function (e) {
            var endX = e.clientX //鼠标拖动时鼠标指针的横向坐标
            var moveLen = dragPoint[i].leftTree + (endX - startX) // （endx-startx）=移动的距离。dragPoint[i].left+移动的距离=左边区域最后的宽度
            var maxT = leftBox[i].clientWidth - dragPoint[i].offsetWidth // 容器宽度 - 左边区域的宽度 = 右边区域的宽度
            if (moveLen < 232) moveLen = 232 // 左边区域的最小宽度为232px
            if (moveLen > maxT - 150) moveLen = maxT //右边区域最小宽度为150px
            dragPoint[i].style.leftTree = moveLen // 设置左侧区域的宽度
            for (let j = 0; j < leftTree.length; j++) {
              leftTree[j].style.width = moveLen + 'px'
              // leftTable[j].style.width =
              leftBox[i].clientWidth - moveLen - 10 + 'px'
            }
          }
          // 鼠标松开事件
          document.onmouseup = function (evt) {
            //颜色恢复
            // dragPoint[i].style.background = "#d6d6d6";
            document.onmousemove = null
            document.onmouseup = null
            dragPoint[i].releaseCapture && dragPoint[i].releaseCapture() //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
          }
          dragPoint[i].setCapture && dragPoint[i].setCapture() //该函数在属于当前线程的指定窗口里设置鼠标捕获
          return false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item--small.el-form-item {
    margin-bottom: 17px;
}
::v-deep .el-table__row > td {
  border: none;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table th.is-leaf {
  border: none;
}
.mt15 {
  padding: 10px 10px 0px;
}
.buttonGroupDiv {
  padding: 10px 10px 0px;
}
//重置密码弹框表单
.restForm {
  ::v-deep .el-form-item {
    margin-bottom: 30px;
  }
}

.liHover:hover {
  cursor: pointer;
  background-color: #ffe6b0;
}

.leftBox,
.rightBox {
  // height: 100%;
  // max-height: 881px;
  // border: 2px solid #1d89e3;
  // padding: 3px 10px 0;
  box-sizing: border-box;
  font-size: 12px;
}

//左侧
.leftBox {
  //第一个div内左侧树状图部分
  float: left;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  // pointer-events:auto;//默认值，元素响应鼠标事件

  .leftTree {
    float: left;
    height: 700px;
    max-height: 750px;
    border-radius: 3px;
    width: 230px;
    position: relative;
    border: 1px solid #cdd1dd;
    padding: 10px 10px 0px 15px;
    .wrap_tree{
      pointer-events:auto;//默认值，元素响应鼠标事件
    }
    //鼠标拖拽图标
    .dragPoint {
      width: 21px;
      height: 21px;
      position: absolute;
      z-index: 2;
      background: #fff;
      top: 47%;
      margin-top: -15px;
      cursor: col-resize;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
    }

    //树状图
    .treeBox {
      margin-top: 0px;
      height: 650px;
      overflow-y: auto;
    }
  }

  .wrap_tree {
    position: relative;

    .historyBox {
      position: absolute;
      top: 30px;
      background-color: white;
      z-index: 99900;
      width: 200px;
      overflow: hidden auto;
      padding: 8px 0px;
      max-height: 376px;
      box-shadow: 0px 0px 10px rgb(232, 232, 232);
      border-radius: 4px;

      ul {
        li {
          padding: 5px 0px;
          margin: 0px 5px;
        }

        li:hover {
          background-color: #e8e8e9;
          cursor: pointer;
        }
      }
    }

    .fuzzySearch {
      width: 200px;
      overflow: hidden auto;
      padding: 8px 0px;
      max-height: 376px;
      box-shadow: 0px 0px 10px rgb(232, 232, 232);

      ul {
        li:hover {
          background-color: #e8e8e9;
          cursor: pointer;
        }

        li {
          height: 30px;
          line-height: 30px;
          padding-left: 14px;
          border: 1px solid #c6c6c6;
          color: gray;
          margin-top: -1px;
        }
      }
    }

    //树状图
    .treeBox {
      padding-top: 10px;

      .el-tree-node:focus > .el-tree-node__content {
        background-color: #ffe6b0;
        color: black;
        border: 1px #ffb951 solid;
        // height: 18px;
        opacity: 0.8;
      }

      .el-tree-node__content {
        &:hover {
          background-color: #ffe6b0;
        }
      }

      .el-tree-node__content .el-tree-node__label {
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }

  .liHover:hover {
    cursor: pointer;
    background-color: #ffe6b0;
  }
}
</style>
<style>
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #ffe6b0;
}
</style>
